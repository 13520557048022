import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import HeaderStats from "components/Headers/HeaderStats";
import DataTable from "react-data-table-component";
import { Button } from "@mui/material";
import CreateIcon from "@material-ui/icons/Create";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { format } from "date-fns";
import axios_auth from "../../../utils/axios/authenticated";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

function CarsIndex() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");

  const columns = [
    // {
    //   name: "ID",
    //   selector: (row) => row._id,
    //   sortable: true,
    // },
    {
      name: "Emri",
      width: "15%",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Lloji",
      selector: (row) => row.transmission,
      sortable: true,
    },
    {
      name: "Viti Prodhimit",
      selector: (row) => row.prodYear,
      sortable: true,
    },
    {
      name: "Motorri",
      selector: (row) => row.engine,
      sortable: true,
    },
    {
      name: "Targa",
      selector: (row) => row.carPlate,
      sortable: true,
    },
    {
      name: "Publikuar",
      selector: (row) => (row.isPublished ? "Po" : "Jo"),
      sortable: true,
    },

    {
      name: "Krijuar më",
      selector: (row) =>
        format(new Date(row.created_at), "dd/MM/yyyy hh:mm:ss"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Ka foto",
      selector: (row) =>
        row.image ? (
          <a href={process.env.REACT_APP_BACKEND_URL + "/uploads/" + row.image}>
            <Button type="button" variant="contained" color="primary">
              <RemoveRedEyeOutlinedIcon />
            </Button>
          </a>
        ) : (
          "Jo"
        ),
      sortable: true,
    },
    {
      name: "Ndrysho Publikimin",
      cell: (row) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const req = axios_auth
              .post(
                `${process.env.REACT_APP_API_URL}/car/change_access/${row._id}`
              )
              .then((res) => {
                if (res.data.success) {
                  row.isPublished = !row.isPublished;
                  setCars([...cars]);
                  Swal.fire({
                    title: "Publikimi u ndryshua",
                    text: "Publikimi u ndryshua me sukses",
                    icon: "success",
                    confirmButtonText: "OK",
                  });
                } else {
                  Swal.fire({
                    title: "Publikimi nuk u ndryshua",
                    text: "Publikimi nuk u ndryshua",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                Swal.fire({
                  title: "Publikimi nuk u ndryshua",
                  text: "Publikimi nuk u ndryshua",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              });
          }}
        >
          <SwapHorizIcon />
        </Button>
      ),
    },
    {
      name: "Përditso",
      cell: (row) => (
        <Link to={`/admin/cars/edit/${row._id}`}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <CreateIcon />
          </Button>
        </Link>
      ),
    },
    {
      name: "Fshi",
      cell: (row) => (
        <Button
          style={{
            color: "red",
            backgroundColor: "white",
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            Swal.fire({
              title: `Do që ta fshini makinën '${row.name}?'`,
              // text: `Po e ke fshi i ke qi robt`,
              icon: "question",
              // delete
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Po, fshije!",
              cancelButtonText: "Jo, anullo!",
            }).then((result) => {
              if (result.value) {
                const req = axios_auth
                  .delete(`${process.env.REACT_APP_API_URL}/car/${row._id}`)
                  .then((res) => {
                    if (res.data.success) {
                      row.isPublished = !row.isPublished;
                      setCars([...cars]);
                      Swal.fire({
                        title: "Publikimi u fshi",
                        text: "Publikimi u fshi me sukses",
                        icon: "success",
                        confirmButtonText: "OK",
                      });
                      setCars(cars.filter((car) => car._id !== row._id));
                    } else {
                      Swal.fire({
                        title: "Publikimi nuk u fshi",
                        text: "Publikimi nuk u fshi",
                        icon: "error",
                        confirmButtonText: "OK",
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    Swal.fire({
                      title: "Publikimi nuk u fshi",
                      text: "Publikimi nuk u fshi",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                  });
              }
            });
          }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_auth.get(`/cars`);
        console.log(res.data.cars);
        setCars(res.data.cars);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setCars([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const [searched, setSearched] = useState("");
  const [type, setType] = React.useState("");
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600  pb-32 pt-12 mt-7">
          <DataTable
            style={{
              marginTop: "10px",
            }}
            color="blue"
            title="Makinat"
            columns={columns}
            data={cars}
            pagination
            subHeader
            persistTableHead
            selectableRowsHighlight
            selectableRowsHighlightAuto
            subHeaderAlign="left"
            subHeaderClassName="bg-dark text-white"
            subHeaderComponentStyle={{
              backgroundColor: "#333",
              color: "#fff",
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "10px",
            }}
          />
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}

export default CarsIndex;
