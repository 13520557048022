import React, { useEffect, useState } from "react";
import axios_auth from "../../utils/axios/authenticated";
import { Box } from "@mui/material";
import { Rings } from "react-loader-spinner";

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    carNumber: 0,
    reservationNumber: 0,
    reservationStoredThisWeek: 0,
    reservationStoredThisMonth: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_auth.get(`/stats`);
        console.log(res.data.cars);
        setStats({
          carNumber: res.data.carNumber,
          reservationNumber: res.data.reservationNumber,
          reservationStoredThisWeek: res.data.reservationStoredThisWeek,
          reservationStoredThisMonth: res.data.reservationStoredThisMonth,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    <Box display="flex" justifyContent="center" alignItems="center">
      <Rings height="100" width="100" color="#0EA5E9" ariaLabel="loading" />
    </Box>;
  }

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Numri Makinave"
                  statTitle={stats.carNumber}
                  statArrow="up"
                  statPercent="3.48"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Numri Rezervimeve gjithsej"
                  statTitle={stats.reservationNumber}
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Numri Rezervimeve 30 ditët e fundit"
                  statTitle={stats.reservationStoredThisMonth}
                  statArrow="down"
                  statPercent="1.10"
                  statPercentColor="text-orange-500"
                  statDescripiron="Since yesterday"
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Numri i Rezervimeve këtë jave"
                  statTitle={stats.reservationStoredThisWeek}
                  statArrow="up"
                  statPercent="12"
                  statPercentColor="text-emerald-500"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
