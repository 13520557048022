/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// import css from "./styles.css";
import "../assets/styles/googlemaps.css";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Select, TextField } from "@material-ui/core";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, createSearchParams } from "react-router-dom";
import { format } from "date-fns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
const validationSchema = yup.object({
  startDate: yup
    .date()
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "Starting date must be today or later"
    )
    .required("Starting date is required"),
  endDate: yup
    .date()
    .min(1, "Return date must be at least 1 day after starting date")
    .when("startDate", (startDate, schema) =>
      schema.min(startDate, "Invalid Dates")
    )
    .required("Return of car date is required"),
});

export default function Index() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const startDate = format(values.startDate, "dd-MM-yyyy");
      const endDate = format(values.endDate, "dd-MM-yyyy");
      console.log(startDate, endDate);

      navigate({
        pathname: "search-car",
        search: createSearchParams({
          startDate: startDate,
          endDate: endDate,
        }).toString(),
      });
    },
  });
  return (
    <>
      <IndexNavbar fixed />
      <Grid
        container
        style={{
          marginTop: "100px",
          marginBottom: "50px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "500px",
          backgroundImage: `linear-gradient(
    to right,
      rgba(255, 255, 255, 1),
       rgba(255, 255, 255, 0.3),
       rgba(255, 255, 255, 0.2),
       rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0),
                  rgba(255, 255, 255, 0)
      ),
      
      url("/img/test2.jpeg")`,
        }}
      >
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
              height: "350px",
              marginTop: "50px",
            }}
            spacing={1}
            sx={{
              width: "300px",
              margin: "auto",
            }}
            md={{
              width: "350px",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Typography
                variant="h6"
                className="text-xl font-bold "
                style={{
                  // set on center
                  fontWeight: "bold",
                  color: "#1976D2",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Search for a car
              </Typography>
              <FormControl
                style={{
                  width: "230px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    marginLeft: "-12px",
                  }}
                >
                  Pick-up location
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"TIA"}
                  label="Pick-up location"
                  onChange={formik.handleChange}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <MenuItem value={"TIA"}>
                    Tirana International Airport
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              {/* col =  */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  inputVariant="outlined"
                  label="Pick-up date"
                  id="date-picker-dialog"
                  name="startDate"
                  disablePast
                  inputFormat="dd/MM/yyyy HH:mm"
                  onChange={(val) => {
                    formik.setFieldValue("startDate", val);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                  // format date-month-year
                  error={formik.errors.startDate && formik.touched.startDate}
                />
              </LocalizationProvider>

              <FormHelperText style={{ color: "#D32F2F", margin: "20px" }}>
                {formik.errors.startDate &&
                  formik.touched.startDate &&
                  formik.errors.startDate}
              </FormHelperText>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  inputVariant="outlined"
                  id="date-picker-dialog"
                  label="Return date"
                  disablePast
                  fullWidth
                  inputFormat="dd/MM/yyyy HH:mm"
                  name="endDate"
                  onChange={(val) => {
                    formik.setFieldValue("endDate", val);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                  // error={formik.errors.endDate && formik.touched.endDate}
                />

                <FormHelperText style={{ color: "#D32F2F", margin: "20px" }}>
                  {formik.errors.endDate &&
                    formik.touched.endDate &&
                    formik.errors.endDate}
                </FormHelperText>
              </LocalizationProvider>
              <Box textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  Search
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: { xs: "none", lg: "block" },
          }}
          md={7}
        ></Grid>
      </Grid>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src="https://thumbs.dreamstime.com/b/sign-arrow-point-to-rent-car-service-airport-sign-arrow-point-to-rent-car-service-airport-121551335.jpg"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    Book your car with us now!
                  </h4>
                  {/* <p className="text-md font-light mt-2 text-white">Avion</p> */}
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-map-marker"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Perfect Location
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Directly in front of Tirana International Airport
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-life-ring"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        24/7 Support
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Multiple languages support: Albanian, English, German,
                        Italian, Macedonian
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-list-ol"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Wide variety of quality cars
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        A selection of the best luxurious/economical cars
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-trophy"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Outstanding Services
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Unlimited mileage for all our customers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-16 bg-blueGray-200 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      <Grid
        container
        style={{
          marginTop: "30px",
          marginBottom: "100px",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="50px"
        >
          <Typography variant="h5" align="center">
            <i
              class="fa fa-map-pin"
              aria-hidden="true"
              style={{
                color: "red",
              }}
            ></i>
          </Typography>
          <Typography variant="h5" align="center">
            Find us on google maps!
          </Typography>{" "}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            justifyContent: "center",
          }}
        >
          <div
            className="mapouter"
            style={{
              position: "relative",
              "text-align": "right",
              height: "400px",
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            <div className="gmap_canvas" style={{ overflow: "hidden" }}>
              <iframe
                width="100%"
                height="100%"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Rent%20and%20Ride%20Albania&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
              ></iframe>
              <br />
            </div>
          </div>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}
