import { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "views/Index.js";
import SearchCar from "views/SearchCar";
import BookCar from "views/BookCar";
import Login from "views/auth/Login";
import CarsIndex from "views/admin/cars/Index";
import Dashboard from "views/admin/Dashboard";
import EditCar from "views/admin/cars/EditCar";
import StoreCar from "views/admin/cars/StoreCar";
import Addons from "views/admin/addons/Index";
import StoreAddons from "views/admin/addons/Store";
import ReservationsIndex from "views/admin/reservations/Index";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import { initialState } from "redux/user/userSlice";
import { login } from "redux/user/userSlice";
import { logout } from "redux/user/userSlice";
import RequireAuth from "components/RequireAuth";
import StoreReservation from "views/admin/reservations/Store";
import Contact from "views/Contact";
import ContactUs from "views/admin/contact_us/Index";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Box } from "@mui/material";
import { Rings } from "react-loader-spinner";
import PageNotFound from "views/PageNotFound";
import UpdateAddons from "views/admin/addons/edit";

const App = () => {
  const session = useSelector((state) => state.user.user);
  const isAuth = useSelector((state) => state.user.isLoggedIn);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  let token = localStorage.getItem("token");
  useEffect(() => {
    try {
      async function initializeToken() {
        dispatch(initialState());
        if (token) {
          await axios
            .get(`${process.env.REACT_APP_API_URL}/user/current`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              dispatch(login(res.data));
              setLoading(false);
            });
        } else {
          dispatch(logout());
          setLoading(false);
          console.log("no token");
        }
      }
      initializeToken();
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  console.log(JSON.stringify(session) + isAuth);
  console.log(session?.role + " XX S");

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Rings height="100" width="100" color="#0EA5E9" ariaLabel="loading" />
      </Box>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <RequireAuth allowedRoles={1} session_role={session?.role} />
            }
          >
            {/* Admin */}
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/cars" element={<CarsIndex />} />
            <Route path="/admin/cars/store" element={<StoreCar />} />
            <Route path="/admin/cars/edit/:id" element={<EditCar />} />
            <Route path="/admin/reservations" element={<ReservationsIndex />} />
            <Route path="/admin/contact_us" element={<ContactUs />} />
            <Route
              path="/admin/reservations/store"
              element={<StoreReservation />}
            />
            <Route path="/admin/addons" element={<Addons />} />
            <Route path="/admin/addons/store" element={<StoreAddons />} />
            <Route path="/admin/addons/edit/:id" element={<UpdateAddons />} />
          </Route>

          {/* Auth */}
          <Route path="/auth" element={<Login />} />

          {/* User */}
          <Route path="/" exact element={<Index />} />
          <Route path="/search-car" exact element={<SearchCar />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/book-car/:id" exact element={<BookCar />} />

          {/* error 404 */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
