import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import AdminNavbar from "components/Navbars/AdminNavbar";
import axios_auth from "../../../utils/axios/authenticated";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const validationSchema = yup.object({
  name: yup
    .string("String required")
    .required("Emri është i detyrueshëm")
    .min(2, "Emri duhet të jetë më i gjatë se 2 karaktere"),
  price_in_euro_per_day: yup
    .number("Number required")
    .required("Çmimi është i detyrueshëm")
    .min(0, "Çmimi duhet të jetë më i madh se 0"),
  description: yup
    .string("String required")
    .required("Përshkrimi është i detyrueshëm"),
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UpdateAddons() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [addons, setAddons] = useState(null);
  useEffect(() => {
    async function getCar() {
      await axios_auth
        .get("/addons/" + id)
        .then((res) => {
          console.log(res.data);
          setAddons(res.data.addons);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getCar();
  }, []);

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const formik = useFormik({
    initialValues: {
      name: addons?.name,
      price_in_euro_per_day: addons?.price_in_euro_per_day,
      description: addons?.description,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      axios_auth
        .put(`${process.env.REACT_APP_API_URL}/edit_addons/${id}`, values)
        .then((res) => {
          if (res.data.success) {
            formik.resetForm();
            Swal.fire({
              title: "Success",
              text: "U kry bac",
              icon: "success",
              confirmButtonText: "OK",
            });

            setOpenSuccess(true);
            setTimeout(() => {
              setOpenSuccess(false);
            }, 5000);
            navigate("/admin/addons");
          } else {
            formik.resetForm();
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
          setOpenError(true);
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
        });
    },
  });
  return (
    <>
      <Sidebar />
      <Snackbar open={openSuccess} autoHideDuration={200}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Addonsi u përditsua me sukses
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={200}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Addonsi nuk u përditsua!
          {errorMessage && (
            <Typography variant="body2">{errorMessage}</Typography>
          )}
        </Alert>
      </Snackbar>
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative  pb-32 pt-3 mt-2">
          <Grid container component="main" margin={"20px"}>
            <Grid />

            <div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: "50px",
                }}
              ></Box>
              <Grid container component="main">
                <Grid />

                <div>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginTop: "50px",
                    }}
                  >
                    <Typography component="h1" variant="h5">
                      Përditso Addons
                    </Typography>
                  </Box>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Emri"
                      name="name"
                      InputLabelProps={{ shrink: true }}
                      autoComplete="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />{" "}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="price_in_euro_per_day"
                      label="Çmimi"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      name="price_in_euro_per_day"
                      autoComplete="price_in_euro_per_day"
                      value={formik.values.price_in_euro_per_day}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.price_in_euro_per_day &&
                        Boolean(formik.errors.price_in_euro_per_day)
                      }
                      helperText={
                        formik.touched.price_in_euro_per_day &&
                        formik.errors.price_in_euro_per_day
                      }
                    />{" "}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="description"
                      label="Përshkrimi"
                      name="description"
                      InputLabelProps={{ shrink: true }}
                      autoComplete="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />{" "}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Përditso Addons
                    </Button>
                  </form>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default UpdateAddons;
