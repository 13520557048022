import Footer from "components/Footers/Footer";
import Navbar from "components/Navbars/IndexNavbar";
import Addons from "components/Addons/Addons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useFormik } from "formik";
import * as yup from "yup";
import Snackbar from "@mui/material/Snackbar";
import axios_not_auth from "../utils/axios/not_authenticated";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAN_CART,
} from "../redux/addons/cardSlide";

import { differenceInDays } from "date-fns";

function BookCar() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [car, setCar] = useState({});
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [queryParameterUsed, setQueryParameterUsed] = useState(false);
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [daysToRent, setDaysToRent] = useState(
    startDate && endDate ? differenceInDays(endDate, startDate) + 1 : 1
  );

  const card = useSelector((state) => state.addons.cart);

  const [startDateDateFormat, setStartDateDateFormat] = useState(null);
  const [endDateDateFormat, setEndDateDateFormat] = useState(null);
  const [displayAddons, setDisplayAddons] = useState(true);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextDate, setNextDate] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    )
  );
  const nextDay = new Date(currentDate);
  nextDay.setDate(currentDate.getDate() + 1);

  const handleDatesChanged = (date, type) => {
    let start_date = null;
    let end_date = null;

    if (type === "startDate") {
      start_date = date;
      end_date = formik.values.endDate;
    } else {
      start_date = formik.values.startDate;
      end_date = date;
    }

    const days = differenceInDays(end_date, start_date);

    if (days >= 0) {
      const daysToRent = days + 1;
      setDaysToRent(daysToRent);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start date must be before end date!",
      });
    }
  };

  const formikRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_not_auth.get(`/car/${id}`);
        // check if 404 or other error occured
        if (res.status === 404) {
          setMessageError("Makina nuk u gjet");
          setOpenError(true);
          setLoading(false);
        }

        // console.log(res.data);
        setCar(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setCar([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    const fetchDaysToRent = async () => {
      if (queryParameterUsed === false && startDate && endDate) {
        let [dayOfStartDate, monthOfStartDate, yearOfStartDate] =
          startDate.split("-");
        let [dayOfEndDate, monthOfEndDate, yearOfEndDate] = endDate.split("-");

        const startDateConverted = new Date(
          yearOfStartDate,
          monthOfStartDate - 1,
          dayOfStartDate
        );

        const endDateConverted = new Date(
          +yearOfEndDate,
          monthOfEndDate - 1,
          +dayOfEndDate
        );
        setStartDateDateFormat(startDateConverted);
        setEndDateDateFormat(endDateConverted);

        const days = differenceInDays(endDateConverted, startDateConverted);

        if (days > 0) {
          setDaysToRent(days);
          setQueryParameterUsed(true);
        }
      }
    };
    const fetchAddons = async () => {
      try {
        const res = await axios_not_auth.get(`/addons`);
        if (res.status === 404) {
          setOpenError(true);
          setLoading(false);
        }
        setAddons(res.data.addons);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setAddons([]);
        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    fetchData();
    fetchDaysToRent();
    fetchAddons();
  }, [id]);

  const validationSchema = yup.object({
    name: yup
      .string("String required")
      .required("Name is required")
      .min(2, "Name must be at least 2 characters"),
    surname: yup
      .string("String required")
      .required("Surname is required")
      .min(2, "Surname must be at least 2 characters"),
    email: yup
      .string("String required")
      .required("Email is required")
      .email("Email is invalid"),
    startDate: yup
      .date()
      .min(
        new Date(new Date().setDate(new Date().getDate() - 1)),
        "Starting date must be at least 1 day from today"
      )
      .required("Starting date is required"),
    endDate: yup
      .date()
      .min(new Date(), "Return date must be after today")
      .when(
        "startDate",
        (startDate, schema) => startDate && schema.min(startDate),
        "Return date must be after starting date"
      )
      .required("Return date is required"),
    country: yup.string("String required"),
    city: yup.string("String required"),
    address: yup.string("String required"),
    // birthday older than 18
    telNo: yup
      .string("String required")
      .required("Telephone number is required")
      .min(10, "Telephone number must be at least 10 characters"),
    // acceptTerms: yup
    //   .boolean()
    //   .oneOf([true], "You must accept the terms and conditions")
    //   .required("You must accept the terms and conditions"),
  });
  // console.log(car.name + "  1 " + car.carPlate);
  const formik = useFormik({
    innerRef: formikRef,
    initialValues: {
      name: "",
      surname: "",
      email: "",
      startDate: queryParameterUsed ? startDateDateFormat : currentDate,
      endDate: queryParameterUsed ? endDateDateFormat : nextDate,
      telNo: "",
      country: "",
      city: "",
      address: "",
      carName: car.name,
      carPlate: car.carPlate,
      information: "",
      // acceptTerms: false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      axios_not_auth
        .post(`${process.env.REACT_APP_API_URL}/reservation`, {
          ...values,
          cart: card,
          carId: id,
          startDate: values.startDate,
          endDate: values.endDate,
        })
        .then((res) => {
          if (res.data.success) {
            formik.resetForm();
            setDisplayAddons(false);
            dispatch(CLEAN_CART());

            Swal.fire({
              title: "Success",
              text:
                "Thank you for your reservation, we will contact you soon. \n The total amount of your reservation is : " +
                res.data.totalPrice +
                "€",
              icon: "success",
              confirmButtonText: "OK",
            })
              // if press confirm button redirect to home page
              .then(() => {
                navigate("/");
              })
              .catch((err) => {
                console.log(err);
              });

            setOpenSuccess(true);
            setTimeout(() => {
              setOpenSuccess(false);
            }, 5000);
          } else {
            formik.resetForm();
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
        });
    },
  });

  if (loading) {
    return <p>Loading</p>;
  }
  return (
    <>
      <Navbar />

      <Snackbar open={openSuccess} autoHideDuration={200}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Your request was submitted successfully
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={200}>
        <Alert severity="error" sx={{ width: "100%" }}>
          There was an error submitting your request
        </Alert>
      </Snackbar>

      <Grid
        container
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <Grid item xs={12} md={6}>
          <Card
            fullWidth
            style={{
              // margin left
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <CardMedia
              component="img"
              alt={car.title}
              width="100%"
              crossOrigin="anonymous"
              image={
                process.env.REACT_APP_BACKEND_URL + "/uploads/" + car.image
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {car.name}
              </Typography>
              {/* <Typography gutterBottom variant="h6" component="div">
                {car.price_in_euro_per_day} € / for day
                <p>
                  {daysToRent > 0 ? (
                    <b>
                      Total for {daysToRent} days (
                      {car.price_in_euro_per_day * daysToRent} € )
                    </b>
                  ) : null}
                </p>
              </Typography> */}

              <Grid container spacing={2} marginBottom={"20px"}>
                <Grid item xs={12} sm={6}>
                  {card.length > 0 ? (
                    <p>
                      <b>
                        Total for {daysToRent} day(s) (
                        {(car.price_in_euro_per_day +
                          card.reduce(
                            (a, b) => a + b.price * b.quantity,
                            0 * daysToRent
                          )) *
                          daysToRent}
                        € )
                      </b>
                    </p>
                  ) : (
                    <p>
                      <b>
                        Total for {daysToRent} days (
                        {car.price_in_euro_per_day * daysToRent} € )
                      </b>
                    </p>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  Car: {car.price_in_euro_per_day} € / for day
                </Grid>
                <Grid item xs={12} sm={6}>
                  {card.length > 0 && (
                    <p>
                      {" "}
                      Extra:
                      {card.reduce(
                        (a, b) => a + b.price * b.quantity,
                        0 * daysToRent
                      ) + " €"}
                    </p>
                  )}
                </Grid>
              </Grid>

              <Typography variant="body2" color="text.secondary">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <b>Engine:</b> {car.engine}
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <b>Fuel:</b> {car.fuel}
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <b>Transmission:</b> {car.transmission}
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <b>Nr of seats:</b> {car.nrSeats}
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <b>Nr of doors:</b> {car.number_of_doors}
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <b>Nr of bags:</b> {car.number_of_bags}
                  </Grid>
                  {/* <Grid item xs={6}>
                    <b> Year:</b> {car.prodYear}
                  </Grid>{" "} */}
                  <Grid item xs={12}>
                    <i>{car.description}</i>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item xs={12} md={6}>
          <Grid container component="main">
            <Grid />

            <div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: "50px",
                }}
              >
                <Typography component="h1" variant="h5">
                  Book car "{car.name}"
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <input
                  hidden
                  name="carName"
                  value={car.name}
                  onChange={formik.handleChange}
                />
                <input hidden name="carPlate" value={car.carPlate} />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="surname"
                  label="Surname"
                  name="surname"
                  autoComplete="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="telNo"
                  label="Telephone number"
                  name="telNo"
                  value={formik.values.telNo}
                  onChange={formik.handleChange}
                  error={formik.touched.telNo && Boolean(formik.errors.telNo)}
                  helperText={formik.touched.telNo && formik.errors.telNo}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="telNo"
                  label="Country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="city"
                  label="City and Zip Code"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="city"
                  label="Address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="telNo"
                  label="Information"
                  name="information"
                  rows={3}
                  maxRows={5}
                  multiline
                  value={formik.values.information}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.information &&
                    Boolean(formik.errors.information)
                  }
                  helperText={
                    formik.touched.information && formik.errors.information
                  }
                />
                {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptTerms"
                        value={formik.values.acceptTerms ? 1 : 0}
                        onChange={(val) => {
                          formik.setFieldValue(
                            "acceptTerms",
                            val.target.checked
                          );
                        }}
                        error={
                          formik.errors.acceptTerms &&
                          formik.touched.acceptTerms
                        }
                        //  validation  checkbox
                      />
                    }
                    label="Accept terms and conditions"
                  />
                  <FormHelperText style={{ color: "#D32F2F", margin: "30px" }}>
                    {formik.errors.acceptTerms &&
                      formik.touched.acceptTerms &&
                      formik.errors.acceptTerms}
                  </FormHelperText>
                </FormGroup> */}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin={3}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disablePast
                      inputVariant="outlined"
                      id="date-picker-dialog"
                      name="startDate"
                      label="Pick-up date"
                      disabled={queryParameterUsed ? true : false}
                      onChange={(val) => {
                        formik.setFieldValue("startDate", val);

                        handleDatesChanged(val, "startDate");
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      onBlur={formik.handleBlur}
                      value={formik.values.startDate}
                      inputFormat="dd/MM/yyyy"
                      error={
                        formik.errors.startDate && formik.touched.startDate
                      }
                    />
                  </LocalizationProvider>

                  <FormHelperText style={{ color: "#D32F2F", margin: "30px" }}>
                    {formik.errors.startDate &&
                      formik.touched.startDate &&
                      formik.errors.startDate}
                  </FormHelperText>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputVariant="outlined"
                      id="date-picker-dialog"
                      label="Return date"
                      disablePast
                      disabled={queryParameterUsed ? true : false}
                      fullWidth
                      name="endDate"
                      onChange={(val) => {
                        formik.setFieldValue("endDate", val);
                        handleDatesChanged(val, "endDate");
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      onBlur={formik.handleBlur}
                      value={formik.values.endDate}
                      inputFormat="dd/MM/yyyy"
                      // error={formik.errors.endDate && formik.touched.endDate}
                    />
                    <FormHelperText
                      style={{ color: "#D32F2F", margin: "30px" }}
                    >
                      {formik.errors.endDate &&
                        formik.touched.endDate &&
                        formik.errors.endDate}
                    </FormHelperText>
                  </LocalizationProvider>
                </Box>{" "}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginBottom={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "20px" }}
                    onClick={() => {
                      setDisplayAddons(!displayAddons);
                    }}
                  >
                    {displayAddons ? "Hide addons" : "Show addons"}
                  </Button>
                </Box>
                {displayAddons &&
                  addons.length > 0 &&
                  addons.map((addon) => {
                    return (
                      <Grid
                        key={addon.id}
                        container
                        spacing={3}
                        marginBottom={"30px"}
                        paddingLeft={"40px"}
                        paddingRight={"40px"}
                      >
                        <Paper
                          sx={{
                            p: 2,
                            margin: "auto",
                            maxWidth: 500,
                            flexGrow: 1,
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#1A2027"
                                : "#fff",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={8} md={8} container>
                              <Grid
                                item
                                xs
                                container
                                direction="column"
                                spacing={2}
                              >
                                <Grid item xs>
                                  <Typography
                                    gutterBottom
                                    variant="subtitle1"
                                    component="div"
                                  >
                                    {addon.name}
                                  </Typography>
                                  <Typography variant="body2" gutterBottom>
                                    {addon.description}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {addon.price_in_euro_per_day} Euro per day
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {/* Danger Button */}
                                  {/* <Button
                                    // variant="contained"
                                    sx={{ cursor: "pointer" }}
                                    style={{
                                      color: "#D32F2F",
                                    }}
                                    onClick={() => {
                                      REMOVE_ITEM_FROM_CART(addon._id);
                                    }}
                                  >
                                    Remove Addon
                                  </Button> */}
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle1" component="div">
                                  {addon.price}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid container>
                                <Button
                                  size="small"
                                  // disableElevation
                                  variant="contained"
                                  style={{
                                    minWidth: "30px",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      REMOVE_FROM_CART({
                                        id: addon._id,
                                      })
                                    );
                                  }}
                                >
                                  -
                                </Button>
                                <p
                                  style={{
                                    margin: "0px 10px",
                                  }}
                                >
                                  {card?.find((x) => x.id === addon._id)
                                    ? card?.find((x) => x.id === addon._id)
                                        .quantity
                                    : 0}
                                </p>
                                <Button
                                  size="small"
                                  disableElevation
                                  variant="contained"
                                  style={{
                                    minWidth: "30px",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      ADD_TO_CART({
                                        id: addon._id,
                                        name: addon.name,
                                        price: addon.price_in_euro_per_day,
                                      })
                                    );
                                  }}
                                >
                                  +
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                {/* create button center */}
                {card?.length > 0 && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={3}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      type="button"
                      onClick={() => {
                        dispatch(CLEAN_CART());
                      }}
                    >
                      Clear Addons
                    </Button>
                  </Box>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Book car now
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default BookCar;
