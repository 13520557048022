import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import DataTable from "react-data-table-component";
import axios_auth from "../../../utils/axios/authenticated";
import { format } from "date-fns";
import CreateIcon from "@material-ui/icons/Create";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function ReservationsIndex() {
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");

  const columns = [
    {
      name: "Emri",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Çmimi",
      selector: (row) => row.price_in_euro_per_day,
      sortable: true,
    },
    {
      name: "Përshkrimi",
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: "Data e krijimit",
      selector: (row) => format(new Date(row.createdAt), "dd/MM/yyyy hh:mm:ss"),
      sortable: true,
    },
    {
      name: "Përditso",
      cell: (row) => (
        <Link to={`/admin/addons/edit/${row._id}`}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <CreateIcon />
          </Button>
        </Link>
      ),
    },
  ];
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios_auth.get(`/addons`);
        console.log(res.data.addons);
        setAddons(res.data.addons);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setAddons([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {loading && <p>Loading...</p>}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600  pb-32 pt-12 mt-7">
          <DataTable
            style={{
              marginTop: "10px",
            }}
            color="blue"
            title="Addons"
            columns={columns}
            data={addons}
            pagination
            subHeader
            persistTableHead
            selectableRowsHighlight
            selectableRowsHighlightAuto
            subHeaderAlign="left"
            subHeaderClassName="bg-dark text-white"
            subHeaderComponentStyle={{
              backgroundColor: "#333",
              color: "#fff",
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "10px",
            }}
          />
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}

export default ReservationsIndex;
