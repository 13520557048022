/*eslint-disable*/
import React from "react";
import { Link, Navigate } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { logout } from "redux/user/userSlice";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const dispatch = useDispatch();
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            Rent and Ride
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Rent and Ride
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Makinat
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin"
                >
                  <i className={"fas fa-tv mr-2 text-sm text-blueGray-300"}></i>{" "}
                  Homepage
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/cars"
                >
                  <i
                    className={"fas fa-list mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Të dhënat për makinat
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/cars/store"
                >
                  <i
                    className={"fas fa-plus mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Shto Makina
                </Link>
              </li>
            </ul>{" "}
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Rezervimet
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/reservations/store"
                >
                  <i
                    className={"fas fa-plus mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Shto rezervim
                </Link>
              </li>{" "}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/reservations"
                >
                  <i
                    className={"fas fa-list mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Lista rezervimeve
                </Link>
              </li>
            </ul>
            <hr className="my-4 md:min-w-full" />{" "}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Addons
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/addons/store"
                >
                  <i
                    className={"fas fa-plus mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Shto
                </Link>
              </li>{" "}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/addons"
                >
                  <i
                    className={"fas fa-list mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Lista
                </Link>
              </li>
            </ul>{" "}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Kërkesat e faqes Contact-us
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  }
                  to="/admin/contact_us"
                >
                  <i
                    className={"fas fa-list mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Lista e kërkesave
                </Link>
              </li>
            </ul>
            <hr className="my-4 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                {/* <Button> */}
                <Button
                  onClick={() => {
                    dispatch(logout());
                    localStorage.removeItem("token");
                    <Navigate to="/" />;
                  }}
                >
                  <i
                    className={"fas fa-sign-out mr-2 text-sm text-blueGray-300"}
                  ></i>{" "}
                  Dil nga Sistemi
                </Button>
                {/* </Button> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
