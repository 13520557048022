import React from "react";

// components


import Sidebar from "components/Sidebar/Sidebar";
import FooterAdmin from "components/Footers/FooterAdmin";
import HeaderStats from "components/Headers/HeaderStats";
// import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminNavbar from "components/Navbars/AdminNavbar";

export default function Dashboard() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
