import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import DataTable from "react-data-table-component";
import axios_auth from "../../../utils/axios/authenticated";
import { format } from "date-fns";
import Swal from "sweetalert2";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

function ReservationsIndex() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");

  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );

  const columns = [
    {
      name: "Emri",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Mbiermi",
      selector: (row) => row.surname,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "150px",
      sortable: true,
    },
    {
      name: "Numri Tel",
      selector: (row) => row.telNo,
      width: "150px",
      sortable: true,
    },
    {
      name: "Makina",
      selector: (row) => row.carName,
      sortable: true,
    },
    {
      name: "Targa",
      selector: (row) => row.carPlate,
      sortable: true,
    },
    {
      name: "Data e marrjes",
      selector: (row) => format(new Date(row.startDate), "dd/MM/yyyy hh:mm"),
      width: "150px",
      sortable: true,
    },
    {
      name: "Data e lënies",
      selector: (row) => format(new Date(row.endDate), "dd/MM/yyyy hh:mm"),
      width: "150px",
      sortable: true,
    },
    {
      name: "Data e krijimit",
      selector: (row) => format(new Date(row.createdAt), "dd/MM/yyyy hh:mm"),
      width: "150px",
      sortable: true,
    },
    {
      name: "Statusi",
      selector: (row) =>
        row.status === 1
          ? "Pending"
          : row.status === 2
          ? "Rejected"
          : row.status === 3
          ? "Accepted"
          : "",
    },
    {
      name: "Ndrysho Publikimin",
      cell: (row) => (
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            // label="Age"
            onChange={(e) => {
              e.preventDefault();
              console.log(e.target.value);
              console.log(row._id);

              axios_auth
                .post(
                  `${process.env.REACT_APP_API_URL}/reservation/change_status/${row._id}/${e.target.value}/${row.carPlate}`,
                  {
                    startDate: row.startDate,
                    endDate: row.endDate,
                  }
                )
                .then((res) => {
                  if (res.data.success) {
                    row.status = e.target.value;
                    setReservations([...reservations]);
                    Swal.fire({
                      title: "Statusi u ndryshua",
                      text: "Statusi u ndryshua me sukses",
                      icon: "success",
                      confirmButtonText: "OK",
                    });
                  } else {
                    Swal.fire({
                      title: "Statusi nuk u ndryshua",
                      text: "Statusi nuk u ndryshua",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  Swal.fire({
                    title: "Statusi nuk u ndryshua",
                    text: "Statusi nuk u ndryshua",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                });
            }}
          >
            <MenuItem value={1}>Pending</MenuItem>
            <MenuItem value={2}>Rejected</MenuItem>
            <MenuItem value={3}>Accepted</MenuItem>
          </Select>
        </FormControl>
      ),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_auth.get(`/reservation`);
        console.log(res.data.cars);
        setReservations(res.data.reservations);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setReservations([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {loading && <p>Loading...</p>}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600  pb-32 pt-12 mt-7">
          <DataTable
            style={{
              marginTop: "10px",
            }}
            color="blue"
            title="Rezervimet"
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            columns={columns}
            data={reservations}
            pagination
            subHeader
            persistTableHead
            selectableRowsHighlight
            selectableRowsHighlightAuto
            subHeaderAlign="left"
            subHeaderClassName="bg-dark text-white"
            subHeaderComponentStyle={{
              backgroundColor: "#333",
              color: "#fff",
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "10px",
            }}
          />
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}

export default ReservationsIndex;
