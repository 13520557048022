import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

import "../assets/styles/error_not_found.css";

const PageNotFound = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h1"
              className="error-not-found"
              // center
              style={{
                textAlign: "center",
              }}
            >
              404
            </Typography>
          </Grid>
          <br />
          <br />
          <Grid item xs={12} md={12}>
            <div className="contant_box_404">
              <h3
                className="h2"
                style={{
                  textAlign: "center",
                }}
              >
                Look like you're lost
              </h3>

              <p
                style={{
                  textAlign: "center",
                }}
              >
                The page you are looking for is not available!
              </p>

              {/* Box add center */}
            </div>

            {/* add button center */}
            <Grid item xs={12} md={12} style={{
                textAlign: "center",
                justifyContent: "center",
            }}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/"
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                Go to Home
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <section className="page_404">
        <div
          className=""
          style={{
            width: "100%",
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  {/* <h1 className="text-center ">404</h1> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
