import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import axios_not_auth from "../utils/axios/not_authenticated";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { differenceInDays } from "date-fns";
import EvStationIcon from "@mui/icons-material/EvStation";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import LuggageIcon from "@mui/icons-material/Luggage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import { Rings } from "react-loader-spinner";

function SearchCar() {
  const navigate = useNavigate();
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [startDateParam, setStartDateParam] = useState(null);
  const [endDateParam, setEndDateParam] = useState(null);
  const [queryParameterUsed, setQueryParameterUsed] = useState(false);
  const [daysToRent, setDaysToRent] = useState(null);
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_not_auth.get(`/cars_published`);
        console.log(res.data.cars);
        setCars(res.data.cars);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setCars([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    const fetchDaysToRent = async () => {
      if (queryParameterUsed === false && startDate && endDate) {
        setStartDateParam(startDate);
        setEndDateParam(endDate);
        setQueryParameterUsed(true);
        let [dayOfStartDate, monthOfStartDate, yearOfStartDate] =
          startDate.split("-");
        let [dayOfEndDate, monthOfEndDate, yearOfEndDate] = endDate.split("-");

        const startDateConverted = new Date(
          yearOfStartDate,
          monthOfStartDate - 1,
          dayOfStartDate
        );
        const endDateConverted = new Date(
          +yearOfEndDate,
          monthOfEndDate - 1,
          +dayOfEndDate
        );

        const days = differenceInDays(endDateConverted, startDateConverted);

        setDaysToRent(days);
      }
    };
    fetchData();
    fetchDaysToRent();
  }, []);
  const [searched, setSearched] = useState("");
  const [type, setType] = React.useState("");

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <>
      <IndexNavbar fixed />
      <section className=" pb-20 relative bg-blueGray-100">
        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <input
              type="text"
              className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none w-full"
              placeholder="Search for a car"
              value={searched}
              onChange={(e) => {
                setSearched(e.target.value);
              }}
            ></input>

            <FormControl
              fullWidth
              style={{
                marginTop: "10px",
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Transmission
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Transmission"
                onChange={handleChange}
                style={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="">-</MenuItem>
                <MenuItem value={"Automatik"}>Automatik</MenuItem>
                <MenuItem value={"Manual"}>Manual</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </section>

      {loading && (
        // create Box to center the spinner
        <Box display="flex" justifyContent="center" alignItems="center">
          <Rings height="100" width="100" color="#0EA5E9" ariaLabel="loading" />
        </Box>
      )}

      <section className="pb-20 relative bg-white">
        <Grid container spacing={1} margin={"5px"} alignItems="stretch">
          {cars
            .filter((item) => {
              if (!searched && type === "") return true;
              if (!searched && type !== "") return item.transmission === type;
              if (searched && type !== "")
                return (
                  item.name.toLowerCase().includes(searched.toLowerCase()) &&
                  item.transmission === type
                );
              if (
                item.name.toLowerCase().includes(searched.toLowerCase()) ||
                item.transmission.toLowerCase().includes(searched.toLowerCase())
                // ||
                // item.prodYear.toLowerCase().includes(searched.toLowerCase())
              ) {
                return true;
              }
            })
            .map((row) => (
              <Grid item xs={12} sm={6} md={3} key={row._id}>
                <Card
                  sx={{ maxWidth: 345 }}
                  height="100%"
                  style={{ height: "100%", marginBottom: "20px" }}
                >
                  <CardMedia
                    component="img"
                    style={{
                      height: "200px",
                      objectFit: "cover",
                    }}
                    crossOrigin="anonymous"
                    alt={row.name}
                    image={
                      process.env.REACT_APP_BACKEND_URL +
                      "/uploads/" +
                      row.image
                    }
                  />
                  
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {row.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          // float right
                          style={{
                            float: "right",
                          }}
                        >
                          <b>
                            {queryParameterUsed && daysToRent > 0
                              ? daysToRent * row.price_in_euro_per_day
                              : row.price_in_euro_per_day}
                            €
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" color="text.secondary">
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6}>
                          <b>
                            <ElectricCarIcon />
                          </b>{" "}
                          {row.engine}
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <b>
                            <EvStationIcon />
                          </b>{" "}
                          {row.fuel}
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <b>
                            <i
                              class="fa fa-cogs"
                              aria-hidden="true"
                              style={{
                                size: "50px",
                              }}
                            ></i>
                          </b>{" "}
                          {row.transmission}
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <b>
                            <AirlineSeatReclineExtraIcon />
                          </b>{" "}
                          {row.nrSeats}
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <b>
                            <SensorDoorIcon />
                          </b>{" "}
                          {row.number_of_doors}
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <b>
                            <LuggageIcon />
                          </b>{" "}
                          {row.number_of_bags}
                        </Grid>
                        {/* <Grid item xs={6}>
                          <b>
                            <CalendarMonthIcon />
                          </b>{" "}
                          {row.prodYear}
                        </Grid>{" "} */}
                      </Grid>
                    </Typography>
                  </CardContent>

                  {startDate && endDate && queryParameterUsed ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ display: "flex", justifyContent: "center" }}
                        fullWidth
                        onClick={() => {
                          navigate({
                            pathname: `/book-car/${row._id}`,
                            search: createSearchParams({
                              startDate: startDate,
                              endDate: endDate,
                            }).toString(),
                          });
                        }}
                      >
                        Book car
                      </Button>
                    </>
                  ) : (
                    <Link to={`/book-car/${row._id}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ display: "flex", justifyContent: "center" }}
                        fullWidth
                      >
                        Book car
                      </Button>
                    </Link>
                  )}
                </Card>
              </Grid>
            ))}
        </Grid>
      </section>
      <Footer />
    </>
  );
}

export default SearchCar;
