import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import AdminNavbar from "components/Navbars/AdminNavbar";
import axios_auth from "../../../utils/axios/authenticated";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const validationSchema = yup.object({
  carId: yup.string().required("Makina është e detyruar"),
  name: yup
    .string("String required")
    .required("Emri është i detyrueshëm")
    .min(2, "Emri duhet të jetë më i gjatë se 2 karaktere"),
  surname: yup
    .string("String required")
    .required("Mbiemri është i detyrueshëm")
    .min(2, "Mbiemri duhet të jetë më i gjatë se 2 karaktere"),
  email: yup
    .string("String required")
    .required("Email është i detyrueshëm")
    .email("Email nuk është në formatin e saktë"),
  startDate: yup
    .date()
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "Data e fillimit duhet të jetë minimumi sot ose ditët në vazhdim"
    )
    .required("Data e fillimit të aplikimit është e detyrueshme"),
  endDate: yup
    .date()
    .min(new Date(), "Data e mbarimit duhet të jetë më e madhe se tani")
    .when(
      "startDate",
      (startDate, schema) => startDate && schema.min(startDate),
      "Data e mbarimit të aplikimit duhet të jetë më e madhe se data e fillimit"
    )
    .required("Data e mbarimit të aplikimit është e detyrueshme"),
  telNo: yup
    .string("String required")
    .required("Numri i telefonit është i detyrueshëm")
    .min(5, "Numri i telefonit duhet të jetë më i gjatë se 5 karaktere"),
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StoreReservation() {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [cars, setCars] = React.useState([]);

  const [loading, setLoading] = useState(true);
  const [messageError, setMessageError] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_auth.get(`/cars`);
        console.log(res.data.cars);
        setCars(res.data.cars);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setCars([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      startDate: new Date(),
      endDate: new Date(),
      telNo: "",
      carId: "",
      // image: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const req = axios_auth
        .post(`${process.env.REACT_APP_API_URL}/reservationManually`, values)
        .then((res) => {
          if (res.data.success) {
            formik.resetForm();

            Swal.fire({
              title: "Success",
              text: "U kry bac",
              icon: "success",
              confirmButtonText: "OK",
            });

            setOpenSuccess(true);
            setTimeout(() => {
              setOpenSuccess(false);
            }, 5000);
          } else {
            formik.resetForm();
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
        });
    },
  });
  return (
    <>
      {loading && <p> Loading ....</p>}
      <Sidebar />
      <Snackbar open={openSuccess} autoHideDuration={200}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Rezervimi u shtua me sukses
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={200}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Rezervimi nuk u shtua!
          {errorMessage && (
            <Typography variant="body2">{errorMessage}</Typography>
          )}
        </Alert>
      </Snackbar>
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative  pb-32 pt-3 mt-2">
          <Grid container component="main" margin={"20px"}>
            <Grid />

            <div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: "50px",
                }}
              ></Box>
              <Grid container component="main">
                <Grid />

                <div>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginTop: "50px",
                    }}
                  >
                    <Typography component="h1" variant="h5">
                      Bëj rezervim
                    </Typography>
                  </Box>
                  <form onSubmit={formik.handleSubmit}>
                    <InputLabel id="test-select-label">Makina</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      autoFocus
                      name="carId"
                      value={formik.values.carId}
                      error={
                        formik.touched.carId && Boolean(formik.errors.carId)
                      }
                      helperText={formik.touched.carId && formik.errors.carId}
                      onChange={(val) => {
                        formik.setFieldValue("carId", val.target.value);
                      }}
                    >
                      {cars.map((car) => (
                        <MenuItem key={car._id} value={car._id}>
                          {car.name} - {car.carPlate}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {formik.errors.carId &&
                        formik.touched.carId &&
                        formik.errors.carId}
                    </FormHelperText>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Emri"
                      name="name"
                      autoComplete="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />{" "}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="surname"
                      label="Mbiemri"
                      name="surname"
                      autoComplete="surname"
                      value={formik.values.surname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.surname && Boolean(formik.errors.surname)
                      }
                      helperText={
                        formik.touched.surname && formik.errors.surname
                      }
                    />{" "}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />{" "}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="telNo"
                      label="Numri Telefonit"
                      name="telNo"
                      value={formik.values.telNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.telNo && Boolean(formik.errors.telNo)
                      }
                      helperText={formik.touched.telNo && formik.errors.telNo}
                    />
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      margin={3}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputVariant="outlined"
                          id="date-picker-dialog"
                          name="startDate"
                          onChange={(val) => {
                            formik.setFieldValue("startDate", val);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          onBlur={formik.handleBlur}
                          value={formik.values.startDate}
                          format="dd/MM/yyyy"
                          error={
                            formik.errors.startDate && formik.touched.startDate
                          }
                        />
                      </LocalizationProvider>

                      <FormHelperText
                        style={{ color: "#D32F2F", margin: "30px" }}
                      >
                        {formik.errors.startDate &&
                          formik.touched.startDate &&
                          formik.errors.startDate}
                      </FormHelperText>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputVariant="outlined"
                          id="date-picker-dialog"
                          label="Data Mbarimit"
                          fullWidth
                          name="endDate"
                          onChange={(val) => {
                            formik.setFieldValue("endDate", val);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          onBlur={formik.handleBlur}
                          value={formik.values.endDate}
                          format="dd/MM/yyyy"
                          // error={formik.errors.endDate && formik.touched.endDate}
                        />
                        <FormHelperText
                          style={{ color: "#D32F2F", margin: "30px" }}
                        >
                          {formik.errors.endDate &&
                            formik.touched.endDate &&
                            formik.errors.endDate}
                        </FormHelperText>
                      </LocalizationProvider>
                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Book car
                    </Button>
                  </form>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default StoreReservation;
