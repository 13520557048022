import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    numberCart: 0,
    cart: [],
    _products: [],
  },
  reducers: {
    ADD_TO_CART: (state, action) => {
      if (state.numberCart === 0) {
        let cart = {
          id: action.payload.id,
          quantity: 1,
          name: action.payload.name,
          price: action.payload.price,
        };

        return {
          ...state,
          numberCart: 1,
          cart: [cart],
        };
      } else {
        let check = false;
        state.cart.map((item, key) => {
          if (item.id === action.payload.id) {
            state.cart[key].quantity++;
            state.numberCart++;
            check = true;
          }
        });
        if (!check) {
          let _cart = {
            id: action.payload.id,
            quantity: 1,
            name: action.payload.name,
            price: action.payload.price,
          };
          return {
            ...state,
            numberCart: state.numberCart + 1,
            cart: [...state.cart, _cart],
          };
        }
      }
    },

    REMOVE_FROM_CART: (state, action) => {
      if (state.numberCart !== 0) {
        state.cart.map((item, key) => {
          if (item.id === action.payload.id) {
            if (item.quantity > 1) {
              state.cart[key].quantity--;
              state.numberCart--;
            } else {
              state.cart.splice(key, 1);
              state.numberCart--;
            }
          }
        });
      }
    },
    REMOVE_ITEM_FROM_CART: (state, action) => {
      if (state.numberCart !== 0) {
        state.cart.map((item, key) => {
          if (item.id === action.payload.id) {
            state.cart.splice(key, 1);
            state.numberCart = state.numberCart - item.quantity;
          }
        });
      }
    },
    CLEAN_CART: (state, action) => {
      state.cart = [];
      state.numberCart = 0;
    },
  },
});

export const {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  LOAD_CURRENT_ITEM,
  ADJUST_ITEM_QTY,
  REMOVE_ITEM_FROM_CART,
  CLEAN_CART,
} = cartSlice.actions;
export default cartSlice.reducer;
