import Footer from "components/Footers/Footer";
import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Box } from "@mui/system";
import axios_not_auth from "../utils/axios/not_authenticated";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string("String required")
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  surname: yup
    .string("String required")
    .required("Surname is required")
    .min(2, "Surname must be at least 2 characters"),
  email: yup
    .string("String required")
    .required("Email is required")
    .email("Email is invalid"),

  country: yup.string("String required"),
  city: yup.string("String required"),
  address: yup.string("String required"),
  // birthday older than 18
  telNo: yup
    .string("String required")
    .required("Telephone number is required")
    .min(10, "Telephone number must be at least 10 characters"),
  // acceptTerms: yup
  //   .boolean()
  //   .oneOf([true], "You must accept the terms and conditions")
  //   .required("You must accept the terms and conditions"),
  request: yup.string("String required").required("Please enter a request"),
});
// console.log(car.name + "  1 " + car.carPlate);

function Contact() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      telNo: "",
      country: "",
      city: "",
      address: "",
      request: "",
      // acceptTerms: false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);

      const req = axios_not_auth
        .post(`${process.env.REACT_APP_API_URL}/contact_us`, {
          values,
        })
        .then((res) => {
          if (res.data.success) {
            formik.resetForm();

            Swal.fire({
              title: "Success",
              text: "Thank you for your request, we will get back to you soon",
              icon: "success",
              confirmButtonText: "OK",
            });

            setOpenSuccess(true);
            setTimeout(() => {
              setOpenSuccess(false);
            }, 5000);
          } else {
            formik.resetForm();
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
        });
    },
  });

  return (
    <>
      <IndexNavbar fixed />
      <Snackbar open={openSuccess} autoHideDuration={200}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Request sent successfully
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={200}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Request failed, please try again
        </Alert>
      </Snackbar>

      <section className=" pb-20 relative bg-blueGray-100">
        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <Typography variant="h4" className="text-center">
              Contact us
            </Typography>

            <Box className="w-full max-w-lg mx-auto">
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="surname"
                      label="Surname"
                      name="surname"
                      autoComplete="surname"
                      value={formik.values.surname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.surname && Boolean(formik.errors.surname)
                      }
                      helperText={
                        formik.touched.surname && formik.errors.surname
                      }
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="telNo"
                      label="Telephone number"
                      name="telNo"
                      value={formik.values.telNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.telNo && Boolean(formik.errors.telNo)
                      }
                      helperText={formik.touched.telNo && formik.errors.telNo}
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="city"
                      label="Address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />{" "}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="city"
                      label="City"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="telNo"
                      label="Country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                    />{" "}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="telNo"
                      label="Request"
                      name="request"
                      rows={3}
                      maxRows={5}
                      multiline
                      value={formik.values.request}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.request && Boolean(formik.errors.request)
                      }
                      helperText={
                        formik.touched.request && formik.errors.request
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="acceptTerms"
                            value={formik.values.acceptTerms ? 1 : 0}
                            onChange={(val) => {
                              formik.setFieldValue(
                                "acceptTerms",
                                val.target.checked
                              );
                            }}
                            error={
                              formik.errors.acceptTerms &&
                              formik.touched.acceptTerms
                            }
                            //  validation  checkbox
                          />
                        }
                        label="Accept terms and conditions"
                      />
                      <FormHelperText
                        style={{ color: "#D32F2F", margin: "30px" }}
                      >
                        {formik.errors.acceptTerms &&
                          formik.touched.acceptTerms &&
                          formik.errors.acceptTerms}
                      </FormHelperText>
                    </FormGroup>
                  </Grid> */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    Send Request
                  </Button>
                </Grid>
              </form>
            </Box>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Contact;
