import FooterSmall from "components/Footers/FooterSmall";
import Navbar from "components/Navbars/AuthNavbar";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "redux/user/userSlice";
import { Alert, Box, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
export default function Login() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState("");

  const validationSchema = yup.object({
    username: yup
      .string("Username must be a string")
      .required("Username is required"),
    password: yup
      .string("Enter your password")
      .min(1, "Password should be of minimum 1 characters length")
      .required("fjalekalimi is required"),
  });

  const loginLogic = (values) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, values);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      loginLogic(values)
        .then((res) => {
          if (res.status === 200) {
            // set localstorage
            localStorage.setItem("token", res.data.accessToken);
            console.log(res.data);
            setOpenSuccessMessage("Login Successful");
            dispatch(login(res.data.user));
            navigate("/admin");
          }
          console.log(res.data);
        })
        .catch((err) => {
          if (err.response.data.status === 1001) {
            setOpenErrorMessage("Të dhënat nuk janë të sakta");
          } else if (err.response.data.status === 1002) {
            setOpenErrorMessage("Email ose Fjalëkalimi nuk janë të sakta");
          } else {
            setOpenErrorMessage("Diçka nuk shkoi siç duhet :(");
          }

          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
            setOpenErrorMessage("");
          }, 5000);
        });
    },
  });

  // const handleSubmit = (values) => {
  //   // prevent default action
  //   // event.preventDefault();
  //   loginLogic(values)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         // set localstorage
  //         localStorage.setItem("token", res.data.accessToken);
  //         console.log(res.data);
  //         setOpenSuccessMessage("Login Successful");
  //         dispatch(login(res.data.user));
  //         navigate("/");
  //       }
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       if (err.response.data.status === 1001) {
  //         setOpenErrorMessage("Të dhënat nuk janë të sakta");
  //       } else if (err.response.data.status === 1002) {
  //         setOpenErrorMessage("Email ose Fjalëkalimi nuk janë të sakta");
  //       } else {
  //         setOpenErrorMessage("Diçka nuk shkoi siç duhet :(");
  //       }

  //       setOpenError(true);
  //       setTimeout(() => {
  //         setOpenError(false);
  //         setOpenErrorMessage("");
  //       }, 5000);
  //     });
  // };
  return (
    <>
      <Snackbar open={openSuccess} autoHideDuration={200}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {openSuccessMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={200}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {openErrorMessage}
        </Alert>
      </Snackbar>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          ></div>

          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-400 text-center mb-6 font-bold"></div>
                    <Box
                      component="form"
                      onSubmit={formik.handleSubmit}
                      method="post"
                      // noValidate
                      sx={{ mt: 1 }}
                    >
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Username
                        </label>
                        <input
                          required
                          type="text"
                          name="username"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Username"
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Fjalëkalimin
                        </label>
                        <input
                          required
                          type="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="fjalëkalimin"
                        />
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Futu në sistem
                        </button>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
