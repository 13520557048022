import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import DataTable from "react-data-table-component";
import axios_auth from "../../../utils/axios/authenticated";
import { format } from "date-fns";
import Swal from "sweetalert2";

function ContactUs() {
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openError, setOpenError] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [age, setAge] = React.useState("");

  const ExpandedComponent = ({ data }) => (
    <p>
      <b>Kërkesa:</b> {data.request}
    </p>
  );

  const columns = [
    {
      name: "Emri",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Mbiermi",
      selector: (row) => row.surname,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Numri Tel",
      selector: (row) => row.telNo,
      sortable: true,
    },
    {
      name: "Shteti",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Qyteti",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "Adresa",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Koha e dërgesës",
      selector: (row) => format(new Date(row.createdAt), "dd/MM/yyyy hh:mm:ss"),
      sortable: true,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios_auth.get(`/contact_us`);
        console.log(res.data.cars);
        setContact(res.data.contact);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setContact([]);

        setTimeout(() => {
          setMessageError("Pati Nje problem :(");
          setOpenError(true);
        }, 3000);
        setMessageError("");
        setOpenError(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {loading && <p>Loading...</p>}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600  pb-32 pt-12 mt-7">
          <DataTable
            style={{
              marginTop: "10px",
            }}
            color="blue"
            title="Kërkesat e Kontaktit"
            columns={columns}
            data={contact}
            pagination
            subHeader
            persistTableHead
            selectableRowsHighlight
            selectableRowsHighlightAuto
            subHeaderAlign="left"
            subHeaderClassName="bg-dark text-white"
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            subHeaderComponentStyle={{
              backgroundColor: "#333",
              color: "#fff",
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "10px",
            }}
          />
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}

export default ContactUs;
