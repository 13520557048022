import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import AdminNavbar from "components/Navbars/AdminNavbar";
import axios_auth from "../../../utils/axios/authenticated_multidata";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";

const validationSchema = yup.object({
  name: yup
    .string("String required")
    .required("Emri është i detyrueshëm")
    .min(2, "Emri duhet të jetë më i gjatë se 2 karaktere"),
  fuel: yup.string("String required").required("Fuel është i detyrueshëm"),

  prodYear: yup
    .string("String required")
    .required("Viti i prodhimit është i detyrueshëm")
    .min(4, "Viti i prodhimit duhet të jetë 4 karaktere")
    .max(4, "Viti i prodhimit duhet të jetë 4 karaktere"),
  engine: yup.string("String required").required("Motorri është i detyrueshëm"),
  carPlate: yup.string("String required").required("Targa është e detyrueshëm"),
  description: yup
    .string("String required")
    .required("Përshkrimi është e detyrueshëm"),
  price_in_euro_per_day: yup
    .number("Number required")
    .required("Çmimi është e detyrueshëm"),
  number_of_doors: yup
    .number("Number required")
    .required("Numri dyerve është e detyrueshëm"),
  number_of_bags: yup
    .number("Number required")
    .required("Numri valixheve është e detyrueshëm"),
  nrSeats: yup
    .number("Number required")
    .required("Nr. i personave të mundur në makinë është e detyrueshme"),

  transmission: yup
    // select box validation
    .string("String required")
    .required("Transmisioni është i detyrueshëm"),
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StoreCar() {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      prodYear: "",
      nrSeats: "",
      engine: "",
      carPlate: "",
      fuel: "",
      description: "",
      price_in_euro_per_day: "",
      number_of_bags: "",
      number_of_doors: "",
      transmission: "",
      isPublished: false,
      // image: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values.image);

      console.log(values.transmission);
      const formData = new FormData();

      formData.append("name", values.name);
      formData.append("fuel", values.fuel);
      formData.append("prodYear", values.prodYear);
      formData.append("nrSeats", values.nrSeats);
      formData.append("engine", values.engine);
      formData.append("carPlate", values.carPlate);
      formData.append("description", values.description);
      formData.append("price_in_euro_per_day", values.price_in_euro_per_day);
      formData.append("number_of_bags", values.number_of_bags);
      formData.append("number_of_doors", values.number_of_doors);
      formData.append("transmission", values.transmission.target.value);
      formData.append("isPublished", values.isPublished);
      formData.append("image", values.image);

      const req = axios_auth
        // .post(`${process.env.API_URL}/car`, values)
        // post request form body to api
        .post(`${process.env.REACT_APP_API_URL}/car`, formData)
        .then((res) => {
          if (res.data.success) {
            formik.resetForm();
            setOpenSuccess(true);
            setTimeout(() => {
              setOpenSuccess(false);
            }, 5000);
          } else {
            if (res.data.code === 409) {
              setOpenError(true);
              setErrorMessage("Makina ekziston");
            }
            formik.resetForm();
            setOpenError(true);
            setTimeout(() => {
              setOpenError(false);
              setErrorMessage("");
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          formik.resetForm();
          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
        });
    },
  });
  return (
    <>
      <Sidebar />
      <Snackbar open={openSuccess} autoHideDuration={200}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Makina u shtua me sukses
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={200}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Makina nuk u shtua!
          {errorMessage && (
            <Typography variant="body2">{errorMessage}</Typography>
          )}
        </Alert>
      </Snackbar>
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative  pb-32 pt-3 mt-2">
          <Grid container component="main" margin={"20px"}>
            <Grid />

            <div>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: "50px",
                }}
              >
                <Typography component="h1" variant="h5">
                  Shto makinën
                </Typography>
              </Box>
              <form
                onSubmit={formik.handleSubmit}
                encType="multipart/form-data"
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Emri Makinës"
                  name="name"
                  autoComplete="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="prodYear"
                  label="Viti Prodhimit"
                  name="prodYear"
                  autoComplete="prodYear"
                  type={"number"}
                  value={formik.values.prodYear}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.prodYear && Boolean(formik.errors.prodYear)
                  }
                  helperText={formik.touched.prodYear && formik.errors.prodYear}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="engine"
                  label="Motorri"
                  name="engine"
                  autoComplete="engine"
                  value={formik.values.engine}
                  onChange={formik.handleChange}
                  error={formik.touched.engine && Boolean(formik.errors.engine)}
                  helperText={formik.touched.engine && formik.errors.engine}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="fuel"
                  label="Karburanti"
                  name="fuel"
                  autoComplete="fuel"
                  value={formik.values.fuel}
                  onChange={formik.handleChange}
                  error={formik.touched.fuel && Boolean(formik.errors.fuel)}
                  helperText={formik.touched.fuel && formik.errors.fuel}
                />{" "}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="carPlate"
                  label="Targa"
                  name="carPlate"
                  autoComplete="carPlate"
                  value={formik.values.carPlate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.carPlate && Boolean(formik.errors.carPlate)
                  }
                  helperText={formik.touched.carPlate && formik.errors.carPlate}
                />{" "}
                <TextField
                  style={{
                    marginBottom: "20px",
                  }}
                  fullWidth
                  multiline
                  rows={2}
                  label="Përshkrimi"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={"number"}
                      id="number_of_doors"
                      label="Numri i dyerve"
                      name="number_of_doors"
                      autoComplete="number_of_doors"
                      value={formik.values.number_of_doors}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number_of_doors &&
                        Boolean(formik.errors.number_of_doors)
                      }
                      helperText={
                        formik.touched.number_of_doors &&
                        formik.errors.number_of_doors
                      }
                    />{" "}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="number_of_bags"
                      type={"number"}
                      id="number_of_bags"
                      label="Numri i valixheve"
                      autoComplete="number_of_bags"
                      value={formik.values.number_of_bags}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number_of_bags &&
                        Boolean(formik.errors.number_of_bags)
                      }
                      helperText={
                        formik.touched.number_of_bags &&
                        formik.errors.number_of_bags
                      }
                    />{" "}
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={"number"}
                      id="price_in_euro_per_day"
                      label="Çmimi për ditë"
                      name="price_in_euro_per_day"
                      autoComplete="price_in_euro_per_day"
                      value={formik.values.price_in_euro_per_day}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.price_in_euro_per_day &&
                        Boolean(formik.errors.price_in_euro_per_day)
                      }
                      helperText={
                        formik.touched.price_in_euro_per_day &&
                        formik.errors.price_in_euro_per_day
                      }
                    />{" "}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={"number"}
                      id="nrSeats"
                      label="Numri i personave të mundur në makinë"
                      name="nrSeats"
                      autoComplete="nrSeats"
                      value={formik.values.nrSeats}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nrSeats && Boolean(formik.errors.nrSeats)
                      }
                      helperText={
                        formik.touched.nrSeats && formik.errors.nrSeats
                      }
                    />{" "}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Lloji
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.transmission}
                        defaultValue={""}
                        label="Lloji"
                        onChange={(val) => {
                          formik.setFieldValue("transmission", val);
                        }}
                        name="transmission"
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      >
                        <MenuItem value={"Automatik"}>Automatik</MenuItem>
                        <MenuItem value={"Manual"}>Manual</MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {formik.errors.transmission &&
                        formik.touched.transmission &&
                        formik.errors.transmission}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isPublished"
                        checked={formik.values.isPublished}
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    label="Publiko"
                  />
                </FormGroup>
                {/* store image */}
                <input
                  type="file"
                  required
                  accept="image/png, image/jpeg"
                  name="image"
                  onChange={(e) => {
                    formik.setFieldValue("image", e.target.files[0]);
                  }}
                />
                <FormHelperText style={{ color: "#D32F2F" }}>
                  Fotot vetëm në formatin PNG ose JPEG
                </FormHelperText>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Shto
                </Button>
              </form>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default StoreCar;
